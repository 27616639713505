@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

input {
  outline:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin: 20px 0px 20px 0px;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2px;
}

h4 {
  font-size: 1.1rem;
  margin: 5px 5px 10px 5px;
}

h5 {
  color: #1a1a1a;
  margin : 5px;
}

.til-container {
  max-width: 1000px;
  width: 80vw;
  min-width: 700px;
  padding: 10px 50px;
  max-height: 85vh;
  overflow: auto;
}

.linkBtn {
  padding: 2px 9px;
  background-color: white;
  border-radius: 9999px;
  border:1px solid #525252;
  box-shadow: rgba(26, 26, 26, 0.1) 1px 2px 2px;
  cursor: pointer;

  transition: font-weight;
  transition-duration: 500ms;
}

.linkBtn:hover {
  /*font-weight: bold;*/
  background: linear-gradient(#f3f3f3 30%, #eeeeee 90%);
}

.tilBtn {
  width: fit-content;
  padding: 1px 8px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  transform: translateX(-0.5rem);

  background-color: white;
  /*border-radius: 9999px;*/
  border:1px solid rgba(203, 203, 203, 0.3);
  box-shadow: rgba(100, 100, 100, 0.1) 1px 2px 2px;
  cursor: pointer;

  transition: font-weight;
  transition-duration: 500ms;
}

.projectModalBtn {
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  border-width: 2px;
  border-radius: 0.25rem;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  cursor: pointer;


  transition: color, background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}

.projectModalBtn:hover {
  color: white;
  background-color: #525252;

  font-weight: bold;
}

.faq-list-box {
  width: 95%;
  margin: 0.5rem auto 0 auto;
}

.faq-item {
  cursor: pointer;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.5s ease;
  position: relative;
}

.answer-box {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.answer-box.active {
  padding: 1rem 0;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.feedback-container {
  background:linear-gradient(120deg, #0fcc55,  #23c1c5);
}
